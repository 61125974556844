import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { NotificationService } from '../app-core/services/notification.service';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard {
    private readonly notificationService = inject(NotificationService);
    private readonly translate = inject(TranslateService);
    private readonly authService = inject(AuthService);


    canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.isAdmin()
            .pipe(
                tap(p => p || this.notificationService.errorMsg(this.translate.instant('route.noPermission')))
            );
    }
}
